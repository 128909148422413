<template>
  <div class="page">
    <div class="top-container">
      <img
        :src="numMap[num].topImage"
        class="top-image"
        oncontextmenu="return false;"
        @click="handlePreviewMap"
      />
    </div>
    <div class="bottom-container">
      <img :src="numMap[num].bottomImage" alt="">
    </div>

    <van-image-preview v-model="imagePreviewVisible" :images="[numMap[num].mapImage]" closeable :show-index	="false">
      <template slot="cover">
        <img :src="[numMap[num].miniMapImage]" class="minimap" />
      </template>
    </van-image-preview>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'

export default {
  name: 'ShoppingGuideRoute',

  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },

  data() {
    return {
      // 路线图序号. 可选值: 1 | 2 | 3
      num: 1,
      numRange: [1, 2, 3],
      // 路线图序号对象 Map
      numMap: {
        1: {
          title: '乔木线',
          topImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/top_01@2x.png',
          bottomImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/bottom_01@2x.png',
          mapImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/map_01@2x.png',
          miniMapImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/minimap_01@2x.png'
        },
        2: {
          title: '地被线',
          topImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/top_02@2x.png',
          bottomImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/bottom_02@2x.png',
          mapImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/map_02@2x.png',
          miniMapImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/minimap_02@2x.png'
        },
        3: {
          title: '灌木花境线',
          topImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/top_03@2x.png',
          bottomImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/bottom_03@2x.png',
          mapImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/map_03@2x.png',
          miniMapImage: 'https://img.miaocang.cc/pcImg/cloud/shopping-guide/route/minimap_03@2x.png'
        }
      },

      imagePreviewVisible: false
    }
  },

  created() {
    let { num = 1 } = this.$route.query
    num = Number(num)
    if (!this.numRange.includes(num)) {
      return
    }
    this.num = num
    document.title = `花木产业导购图 - ${this.numMap[this.num].title}`
  },

  methods: {
    /* 顶部区域的点击事件处理: 查看地图的大图预览 */
    handlePreviewMap() {
      // const { num, numMap } = this
      // ImagePreview({
      //   images: [numMap[num].mapImage],
      //   closeable: true,
      //   showIndex: false,
      //   maxZoom: 10,
      //   getContainer() {
      //     return document.querySelector('.page')
      //   }
      // })
      this.imagePreviewVisible = true
    },
  }
}
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
  background-color: #f3d7b3;
  display: flex;
  flex-direction: column;
  .top-container {
    height: calc((100vw - 80px) * 1.2975);
    max-height: calc((840px - 80px) * 1.2975);
    .top-image {
      pointer-events: auto;
      object-position: 0 -80px;
    }
  }
  ::v-deep .van-image-preview__swipe-item {
    padding-top: 1rem;
    align-items: flex-start;
  }
  .minimap {
    position: fixed;
    bottom: 80px;
    right: 0;
    z-index: 10;
    width: calc(100vw * 0.56);
    max-width: calc(840px * 0.56);
    height: calc(100vw * 0.56 * 0.70);
    max-height: calc(840px * 0.56 * 0.70);
  }
  img {
    width: 100%;
    user-select: none;
    pointer-events: none;
  }
}
</style>